:root {
  --main-color: #000000; /* Changed to black */

  --blue: #333333; /* Dark gray */
  --blue-dark: #000000; /* Black */
  --orange: #666666; /* Medium gray */
  --green-yellow: #999999; /* Light gray */
  --pink-light: #cccccc; /* Very light gray */
  --cyan-light: #e0e0e0; /* Near white */
  --white: #ffffff; /* White */
  --white-alpha-40: rgba(255, 255, 255, 0.4); /* Transparent white */
  --white-alpha-25: rgba(255, 255, 255, 0.25); /* More transparent white */
  --backdrop-filter-blur: blur(1px); /* No change needed here */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}
::before,
::after {
  box-sizing: border-box;
}
h1,
h2 {
  font-weight: 600;
}
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

ul {
  list-style: none;
}
