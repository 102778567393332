body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
  background: linear-gradient(-45deg, black, white, gray, #333);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  font-size: 16px;
  background-attachment: fixed;
  color: black;
  line-height: 1.5;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
  padding: 35px 15px;
}

.blurred {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 300px;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 20px 50px -10px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
}

body.hide-scrolling {
  overflow-y: hidden;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: -1;
  opacity: 0.1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb {
  background-color: black;
}

/* animation keyframes */

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes bounceTop {
  0%,
  100% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(0px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
